import { __assign } from "tslib";
import { ErrorKind } from './error';
import { Parser } from './parser';
import { isDateElement, isDateTimeSkeleton, isNumberElement, isNumberSkeleton, isPluralElement, isSelectElement, isTagElement, isTimeElement } from './types';
function pruneLocation(els) {
  els.forEach(function (el) {
    delete el.location;
    if (isSelectElement(el) || isPluralElement(el)) {
      for (var k in el.options) {
        delete el.options[k].location;
        pruneLocation(el.options[k].value);
      }
    } else if (isNumberElement(el) && isNumberSkeleton(el.style)) {
      delete el.style.location;
    } else if ((isDateElement(el) || isTimeElement(el)) && isDateTimeSkeleton(el.style)) {
      delete el.style.location;
    } else if (isTagElement(el)) {
      pruneLocation(el.children);
    }
  });
}
export function parse(message, opts) {
  if (opts === void 0) {
    opts = {};
  }
  opts = __assign({
    shouldParseSkeletons: true,
    requiresOtherClause: true
  }, opts);
  var result = new Parser(message, opts).parse();
  if (result.err) {
    var error = SyntaxError(ErrorKind[result.err.kind]);
    // @ts-expect-error Assign to error object
    error.location = result.err.location;
    // @ts-expect-error Assign to error object
    error.originalMessage = result.err.message;
    throw error;
  }
  if (!(opts === null || opts === void 0 ? void 0 : opts.captureLocation)) {
    pruneLocation(result.val);
  }
  return result.val;
}
export * from './types';
// only for testing
export var _Parser = Parser;