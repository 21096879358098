import {
  provideRouter,
  withRouterConfig,
  type ExtraOptions,
} from '@angular/router';

import { NetworkAwarePreloadStrategy, type TypedRoute } from '@cosmos/router';
import { provideDataAccessProductInventory } from '@smartlink/products/data-access-product-inventory';

const ROUTER_OPTIONS: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  preloadingStrategy: NetworkAwarePreloadStrategy,
  onSameUrlNavigation: 'reload',
};

const routes: TypedRoute[] = [
  {
    providers: [provideDataAccessProductInventory()],
    loadComponent: async () =>
      (await import('@ad-content/feature-sponsored-content-preview'))
        .EspSponsoredContentPreviewComponent,
    path: 'sponsored-content',
  },
  {
    path: '**',
    loadComponent: async () =>
      (await import(/* webpackChunkName: 'not-found' */ '@cosmos/ui-not-found'))
        .CosmosNotFoundPage,
    data: {
      meta: {
        robots: 'noindex, nofollow',
      },
    },
  },
];

export const provideAppRouting = () => {
  return [provideRouter(routes, withRouterConfig(ROUTER_OPTIONS))];
};
