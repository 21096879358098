import { __extends } from "tslib";
export var ErrorCode;
(function (ErrorCode) {
  // When we have a placeholder but no value to format
  ErrorCode["MISSING_VALUE"] = "MISSING_VALUE";
  // When value supplied is invalid
  ErrorCode["INVALID_VALUE"] = "INVALID_VALUE";
  // When we need specific Intl API but it's not available
  ErrorCode["MISSING_INTL_API"] = "MISSING_INTL_API";
})(ErrorCode || (ErrorCode = {}));
var FormatError = /** @class */function (_super) {
  __extends(FormatError, _super);
  function FormatError(msg, code, originalMessage) {
    var _this = _super.call(this, msg) || this;
    _this.code = code;
    _this.originalMessage = originalMessage;
    return _this;
  }
  FormatError.prototype.toString = function () {
    return "[formatjs Error: ".concat(this.code, "] ").concat(this.message);
  };
  return FormatError;
}(Error);
export { FormatError };
var InvalidValueError = /** @class */function (_super) {
  __extends(InvalidValueError, _super);
  function InvalidValueError(variableId, value, options, originalMessage) {
    return _super.call(this, "Invalid values for \"".concat(variableId, "\": \"").concat(value, "\". Options are \"").concat(Object.keys(options).join('", "'), "\""), ErrorCode.INVALID_VALUE, originalMessage) || this;
  }
  return InvalidValueError;
}(FormatError);
export { InvalidValueError };
var InvalidValueTypeError = /** @class */function (_super) {
  __extends(InvalidValueTypeError, _super);
  function InvalidValueTypeError(value, type, originalMessage) {
    return _super.call(this, "Value for \"".concat(value, "\" must be of type ").concat(type), ErrorCode.INVALID_VALUE, originalMessage) || this;
  }
  return InvalidValueTypeError;
}(FormatError);
export { InvalidValueTypeError };
var MissingValueError = /** @class */function (_super) {
  __extends(MissingValueError, _super);
  function MissingValueError(variableId, originalMessage) {
    return _super.call(this, "The intl string context variable \"".concat(variableId, "\" was not provided to the string \"").concat(originalMessage, "\""), ErrorCode.MISSING_VALUE, originalMessage) || this;
  }
  return MissingValueError;
}(FormatError);
export { MissingValueError };