import { Pipe, type PipeTransform } from '@angular/core';

import type { Languages } from '../consts';
import { CosTranslatePipe } from '../overrides';

@Pipe({
  name: 'languageLabel',
  // eslint-disable-next-line @angular-eslint/no-pipe-impure
  pure: false,
})
export class LanguageLabelPipe
  extends CosTranslatePipe
  implements PipeTransform
{
  override transform(value: Languages): string {
    const langKey = `espCommon.localization.${value}`;
    return super.transform(langKey);
  }
}
