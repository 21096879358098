// @generated from time-data-gen.ts
// prettier-ignore  
export var timeData = {
  "001": ["H", "h"],
  "AC": ["H", "h", "hb", "hB"],
  "AD": ["H", "hB"],
  "AE": ["h", "hB", "hb", "H"],
  "AF": ["H", "hb", "hB", "h"],
  "AG": ["h", "hb", "H", "hB"],
  "AI": ["H", "h", "hb", "hB"],
  "AL": ["h", "H", "hB"],
  "AM": ["H", "hB"],
  "AO": ["H", "hB"],
  "AR": ["H", "h", "hB", "hb"],
  "AS": ["h", "H"],
  "AT": ["H", "hB"],
  "AU": ["h", "hb", "H", "hB"],
  "AW": ["H", "hB"],
  "AX": ["H"],
  "AZ": ["H", "hB", "h"],
  "BA": ["H", "hB", "h"],
  "BB": ["h", "hb", "H", "hB"],
  "BD": ["h", "hB", "H"],
  "BE": ["H", "hB"],
  "BF": ["H", "hB"],
  "BG": ["H", "hB", "h"],
  "BH": ["h", "hB", "hb", "H"],
  "BJ": ["H", "hB"],
  "BL": ["H", "hB"],
  "BM": ["h", "hb", "H", "hB"],
  "BN": ["hb", "hB", "h", "H"],
  "BO": ["H", "hB", "h", "hb"],
  "BQ": ["H"],
  "BR": ["H", "hB"],
  "BS": ["h", "hb", "H", "hB"],
  "BT": ["h", "H"],
  "BW": ["H", "h", "hb", "hB"],
  "BZ": ["H", "h", "hb", "hB"],
  "CA": ["h", "hb", "H", "hB"],
  "CC": ["H", "h", "hb", "hB"],
  "CD": ["hB", "H"],
  "CF": ["H", "h", "hB"],
  "CG": ["H", "hB"],
  "CH": ["H", "hB", "h"],
  "CI": ["H", "hB"],
  "CK": ["H", "h", "hb", "hB"],
  "CL": ["H", "h", "hB", "hb"],
  "CM": ["H", "h", "hB"],
  "CN": ["H", "hB", "hb", "h"],
  "CO": ["h", "H", "hB", "hb"],
  "CP": ["H"],
  "CR": ["H", "h", "hB", "hb"],
  "CU": ["H", "h", "hB", "hb"],
  "CV": ["H", "hB"],
  "CX": ["H", "h", "hb", "hB"],
  "CY": ["h", "H", "hb", "hB"],
  "CZ": ["H"],
  "DE": ["H", "hB"],
  "DG": ["H", "h", "hb", "hB"],
  "DJ": ["h", "H"],
  "DK": ["H"],
  "DM": ["h", "hb", "H", "hB"],
  "DO": ["h", "H", "hB", "hb"],
  "DZ": ["h", "hB", "hb", "H"],
  "EA": ["H", "h", "hB", "hb"],
  "EC": ["H", "hB", "h", "hb"],
  "EE": ["H", "hB"],
  "EG": ["h", "hB", "hb", "H"],
  "EH": ["h", "hB", "hb", "H"],
  "ER": ["h", "H"],
  "ES": ["H", "hB", "h", "hb"],
  "ET": ["hB", "hb", "h", "H"],
  "FI": ["H"],
  "FJ": ["h", "hb", "H", "hB"],
  "FK": ["H", "h", "hb", "hB"],
  "FM": ["h", "hb", "H", "hB"],
  "FR": ["H", "hB"],
  "GA": ["H", "hB"],
  "GB": ["H", "h", "hb", "hB"],
  "GD": ["h", "hb", "H", "hB"],
  "GE": ["H", "hB", "h"],
  "GF": ["H", "hB"],
  "GG": ["H", "h", "hb", "hB"],
  "GH": ["h", "H"],
  "GI": ["H", "h", "hb", "hB"],
  "GM": ["h", "hb", "H", "hB"],
  "GN": ["H", "hB"],
  "GP": ["H", "hB"],
  "GQ": ["H", "hB", "h", "hb"],
  "GR": ["h", "H", "hb", "hB"],
  "GT": ["H", "h", "hB", "hb"],
  "GU": ["h", "hb", "H", "hB"],
  "GW": ["H", "hB"],
  "GY": ["h", "hb", "H", "hB"],
  "HK": ["h", "hB", "hb", "H"],
  "HN": ["H", "h", "hB", "hb"],
  "HR": ["H", "hB"],
  "IC": ["H", "h", "hB", "hb"],
  "ID": ["H"],
  "IE": ["H", "h", "hb", "hB"],
  "IL": ["H", "hB"],
  "IM": ["H", "h", "hb", "hB"],
  "IN": ["h", "H"],
  "IO": ["H", "h", "hb", "hB"],
  "IQ": ["h", "hB", "hb", "H"],
  "IR": ["hB", "H"],
  "IS": ["H"],
  "IT": ["H", "hB"],
  "JE": ["H", "h", "hb", "hB"],
  "JM": ["h", "hb", "H", "hB"],
  "JO": ["h", "hB", "hb", "H"],
  "JP": ["H", "h", "K"],
  "KE": ["hB", "hb", "H", "h"],
  "KG": ["H", "h", "hB", "hb"],
  "KH": ["hB", "h", "H", "hb"],
  "KI": ["h", "hb", "H", "hB"],
  "KM": ["H", "h", "hB", "hb"],
  "KN": ["h", "hb", "H", "hB"],
  "KP": ["h", "H", "hB", "hb"],
  "KR": ["h", "H", "hB", "hb"],
  "KW": ["h", "hB", "hb", "H"],
  "KY": ["h", "hb", "H", "hB"],
  "KZ": ["H", "hB"],
  "LA": ["H", "hb", "hB", "h"],
  "LB": ["h", "hB", "hb", "H"],
  "LC": ["h", "hb", "H", "hB"],
  "LI": ["H", "hB", "h"],
  "LK": ["H", "h", "hB", "hb"],
  "LR": ["h", "hb", "H", "hB"],
  "LS": ["h", "H"],
  "LT": ["H", "h", "hb", "hB"],
  "LU": ["H", "h", "hB"],
  "LV": ["H", "hB", "hb", "h"],
  "LY": ["h", "hB", "hb", "H"],
  "MA": ["H", "h", "hB", "hb"],
  "MC": ["H", "hB"],
  "MD": ["H", "hB"],
  "ME": ["H", "hB", "h"],
  "MF": ["H", "hB"],
  "MH": ["h", "hb", "H", "hB"],
  "MK": ["H", "h", "hb", "hB"],
  "ML": ["H"],
  "MM": ["hB", "hb", "H", "h"],
  "MN": ["H", "h", "hb", "hB"],
  "MO": ["h", "hB", "hb", "H"],
  "MP": ["h", "hb", "H", "hB"],
  "MQ": ["H", "hB"],
  "MR": ["h", "hB", "hb", "H"],
  "MS": ["H", "h", "hb", "hB"],
  "MW": ["h", "hb", "H", "hB"],
  "MX": ["H", "h", "hB", "hb"],
  "MY": ["hb", "hB", "h", "H"],
  "MZ": ["H", "hB"],
  "NA": ["h", "H", "hB", "hb"],
  "NC": ["H", "hB"],
  "NE": ["H"],
  "NF": ["H", "h", "hb", "hB"],
  "NG": ["H", "h", "hb", "hB"],
  "NI": ["H", "h", "hB", "hb"],
  "NL": ["H", "hB"],
  "NP": ["H", "h", "hB"],
  "NR": ["H", "h", "hb", "hB"],
  "NU": ["H", "h", "hb", "hB"],
  "NZ": ["h", "hb", "H", "hB"],
  "OM": ["h", "hB", "hb", "H"],
  "PA": ["h", "H", "hB", "hb"],
  "PE": ["H", "hB", "h", "hb"],
  "PF": ["H", "h", "hB"],
  "PG": ["h", "H"],
  "PH": ["h", "hB", "hb", "H"],
  "PK": ["h", "hB", "H"],
  "PM": ["H", "hB"],
  "PN": ["H", "h", "hb", "hB"],
  "PR": ["h", "H", "hB", "hb"],
  "PS": ["h", "hB", "hb", "H"],
  "PT": ["H", "hB"],
  "PW": ["h", "H"],
  "PY": ["H", "h", "hB", "hb"],
  "QA": ["h", "hB", "hb", "H"],
  "RE": ["H", "hB"],
  "RO": ["H", "hB"],
  "RS": ["H", "hB", "h"],
  "RU": ["H"],
  "SA": ["h", "hB", "hb", "H"],
  "SB": ["h", "hb", "H", "hB"],
  "SC": ["H", "h", "hB"],
  "SD": ["h", "hB", "hb", "H"],
  "SE": ["H"],
  "SG": ["h", "hb", "H", "hB"],
  "SH": ["H", "h", "hb", "hB"],
  "SI": ["H", "hB"],
  "SJ": ["H"],
  "SK": ["H"],
  "SL": ["h", "hb", "H", "hB"],
  "SM": ["H", "h", "hB"],
  "SN": ["H", "h", "hB"],
  "SO": ["h", "H"],
  "SR": ["H", "hB"],
  "SS": ["h", "hb", "H", "hB"],
  "ST": ["H", "hB"],
  "SV": ["H", "h", "hB", "hb"],
  "SX": ["H", "h", "hb", "hB"],
  "SY": ["h", "hB", "hb", "H"],
  "SZ": ["h", "hb", "H", "hB"],
  "TA": ["H", "h", "hb", "hB"],
  "TC": ["h", "hb", "H", "hB"],
  "TD": ["h", "H", "hB"],
  "TF": ["H", "h", "hB"],
  "TG": ["H", "hB"],
  "TL": ["H", "hB", "hb", "h"],
  "TN": ["h", "hB", "hb", "H"],
  "TO": ["h", "H"],
  "TR": ["H", "hB"],
  "TT": ["h", "hb", "H", "hB"],
  "TW": ["hB", "hb", "h", "H"],
  "TZ": ["hB", "hb", "H", "h"],
  "UA": ["H", "hB", "h"],
  "UG": ["hB", "hb", "H", "h"],
  "UM": ["h", "hb", "H", "hB"],
  "US": ["h", "hb", "H", "hB"],
  "UY": ["H", "h", "hB", "hb"],
  "UZ": ["H", "hB", "h"],
  "VA": ["H", "h", "hB"],
  "VC": ["h", "hb", "H", "hB"],
  "VE": ["h", "H", "hB", "hb"],
  "VG": ["h", "hb", "H", "hB"],
  "VI": ["h", "hb", "H", "hB"],
  "VU": ["h", "H"],
  "WF": ["H", "hB"],
  "WS": ["h", "H"],
  "XK": ["H", "hB", "h"],
  "YE": ["h", "hB", "hb", "H"],
  "YT": ["H", "hB"],
  "ZA": ["H", "h", "hb", "hB"],
  "ZM": ["h", "hb", "H", "hB"],
  "af-ZA": ["H", "h", "hB", "hb"],
  "ar-001": ["h", "hB", "hb", "H"],
  "ca-ES": ["H", "h", "hB"],
  "en-001": ["h", "hb", "H", "hB"],
  "es-BO": ["H", "h", "hB", "hb"],
  "es-BR": ["H", "h", "hB", "hb"],
  "es-EC": ["H", "h", "hB", "hb"],
  "es-ES": ["H", "h", "hB", "hb"],
  "es-GQ": ["H", "h", "hB", "hb"],
  "es-PE": ["H", "h", "hB", "hb"],
  "fr-CA": ["H", "h", "hB"],
  "gl-ES": ["H", "h", "hB"],
  "gu-IN": ["hB", "hb", "h", "H"],
  "hi-IN": ["hB", "h", "H"],
  "it-CH": ["H", "h", "hB"],
  "it-IT": ["H", "h", "hB"],
  "kn-IN": ["hB", "h", "H"],
  "ml-IN": ["hB", "h", "H"],
  "mr-IN": ["hB", "hb", "h", "H"],
  "pa-IN": ["hB", "hb", "h", "H"],
  "ta-IN": ["hB", "h", "hb", "H"],
  "te-IN": ["hB", "h", "H"],
  "zu-ZA": ["H", "hB", "hb", "h"]
};