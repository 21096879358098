import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  importProvidersFrom,
  provideExperimentalZonelessChangeDetection,
  type ApplicationConfig,
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';

import { provideAppName } from '@cosmos/core';
import { provideThemes } from '@cosmos/data-access-themes';
import { CosTheme } from '@cosmos/types-common';
import { provideI18nDateFormats } from '@cosmos/util-i18n-dates';

import { environment } from '../environments/environment';

import { provideInterceptors } from './interceptors.provider';
import { provideAppNgxs } from './ngxs.provider';
import { provideAppRouting } from './routing.provider';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAppName('ad-content'),
    provideExperimentalZonelessChangeDetection(),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideInterceptors(),
    provideI18nDateFormats(),
    provideAppNgxs(),

    provideAppRouting(),

    provideThemes({ defaultTheme: CosTheme.ESP, appId: 'ad-content' }),

    environment.providers || [],
    importProvidersFrom(environment.imports || []),
  ],
};
