import { NgModule, type ModuleWithProviders } from '@angular/core';
import {
  // eslint-disable-next-line no-restricted-syntax
  TranslocoModule,
} from '@ngneat/transloco';

import type { ProvideTranslationsConfig } from './interfaces/provide-translations.interface';
import { CosTranslatePipe } from './overrides';
import { GetTranslationContextPipe } from './pipes/translation-context.pipe';
import { provideTranslations } from './utils';

@NgModule({
  declarations: [CosTranslatePipe, GetTranslationContextPipe],
  exports: [TranslocoModule, CosTranslatePipe, GetTranslationContextPipe],
})
export class CosmosUtilTranslationsModule {
  static forRoot(
    provideTranslationsConfig: ProvideTranslationsConfig
  ): ModuleWithProviders<CosmosUtilTranslationsModule> {
    return {
      ngModule: CosmosUtilTranslationsModule,
      providers: provideTranslations(provideTranslationsConfig),
    };
  }
}
