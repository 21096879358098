import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';

import type { AuthSession } from '@asi/auth/types-auth';
import { injectDestroyRef } from '@cosmos/util-common';

const EXPIRATION_OFFSET_MS = 2 * 60 * 1000;

@Injectable({ providedIn: 'root' })
export class AuthTokenService {
  private readonly _session$ = new BehaviorSubject<AuthSession | null>(null);

  constructor() {
    injectDestroyRef().onDestroy(() => this._session$.complete());
  }

  get isExpired() {
    const now = Date.now();
    const expirationDate = this._session$.value?.expires_at;

    return expirationDate != null
      ? now >= expirationDate - EXPIRATION_OFFSET_MS
      : true;
  }

  get refreshToken(): string {
    return this._session$.value?.refresh_token ?? '';
  }

  set token(token: string) {
    const session = this._session$.value;

    session &&
      this._session$.next({
        ...session,
        access_token: token,
      });
  }

  get token(): string {
    return this._session$.value?.access_token ?? '';
  }

  getToken() {
    return this._session$.pipe(map((session) => session?.access_token));
  }

  reset(session: AuthSession | null = null) {
    this._session$.next(session);
  }
}
